<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>內容管理中心</b-breadcrumb-item>
            <b-breadcrumb-item active>文章管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">文章管理</h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="
              checkPermission([consts.POST_LIST_CREATE]) &&
              !!this.query.merchant_id
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="handleCreate"
          >
            <i class="fa fa-plus"></i>新增文章
          </b-button>
          <b-form-select
            v-model="query.merchant_id"
            :options="merchants"
            @change="handlePageReset"
            :disabled="merchants.length == 2"
          ></b-form-select>
          <b-form-select
            class="ml-2"
            v-model="query.status"
            :options="statusOptions"
            @change="handlePageReset"
          >
            <template #first>
              <option disabled value="">發佈狀態</option>
            </template>
          </b-form-select>
          <b-form-select
            class="ml-2"
            v-model="query.publish_status"
            :options="publishStatus"
            @change="handlePageReset"
          >
            <template #first>
              <option disabled value="">上架狀態</option>
            </template>
          </b-form-select>

          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              placeholder="標題＆內容"
              v-model="query.keyword"
              v-on:keyup.enter="handlePageReset"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="posts"
        :fields="fields"
        :busy="isLoading"
        @sort-changed="handleSort"
      >
        <template #cell(image_url)="data">
          <b-img-lazy
            v-if="data.item.image_url"
            class="thumb-image"
            :src="data.item.image_url"
            alt="圖片"
            width="50"
            height="50"
          />
        </template>
        <template #cell(start_at_and_end_at)="data">
          <div class="mb-1">{{ formatDate(data.item.start_at) }} ~</div>
          <div>{{ formatDate(data.item.end_at) }}</div>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-button
            variant="inverse-primary"
            :to="{
              name: 'PostView',
              params: { postId: data.item.id },
            }"
            ><span class="mdi mdi-eye mr-1"></span>
            查看
          </b-button>
          <b-button
            v-if="checkPermission([consts.POST_LIST_MODIFY])"
            class="ml-2"
            variant="inverse-warning"
            :to="{
              name: 'PostEdit',
              params: {
                postId: data.item.id,
              },
            }"
            ><span class="mdi mdi-lead-pencil mr-1"></span>編輯</b-button
          >

          <b-button
            v-if="checkPermission([consts.POST_LIST_MODIFY])"
            class="ml-2"
            variant="inverse-danger"
            @click="handleDelete(data.item.id, data.item.title)"
            ><span class="mdi mdi-delete mr-1"></span>刪除</b-button
          >
        </template>
      </b-table>

      <CustomPagination
        :currentPage="query.page"
        :totalRows="total"
        :perPage="query.per_page"
        @page-change="handlePageChange"
        @per-page-change="handlePerPageChange"
      />
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import postApi from "../../../apis/post";
import merchantApi from "../../../apis/merchant";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import postStatus from "./postStatus";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

const statusMap = Object.entries(postStatus).reduce((map, [text, value]) => {
  map[value] = text;
  return map;
}, {});

export default {
  components: { CustomPagination },
  mixins: [paginationMixin],
  data() {
    return {
      consts,
      isLoading: false,
      search: "",
      posts: [],
      merchantId: null,
      merchants: [{ value: null, text: "請選擇" }],
      statusOptions: [
        { value: null, text: "全部" },
        { value: 0, text: "草稿" },
        { value: 1, text: "發佈" },
        { value: 2, text: "下架" },
      ],
      publishStatus: [
        { value: null, text: "全部" },
        { value: "published", text: "上架中" },
        { value: "unpublished", text: "未上架" },
      ],
      fields: [
        {
          key: "image_url",
          label: "圖片",
        },
        {
          key: "title",
          label: "標題",
          formatter: (value) => {
            if (value) {
              return this.hiddenString(value, 20);
            }
            return "";
          },
        },
        {
          key: "start_at_and_end_at",
          label: "發布/截止時間",
        },
        {
          key: "created_at",
          label: "上稿時間",
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd HH:mm");
          },
        },
        {
          key: "status",
          label: "狀態",
          sortable: true,
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      total: 0,
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        order_by: "desc",
        sort_by: "created_at",
        merchant_id: null,
        publish_status: null,
        status: null,
      },
    };
  },
  computed: {
    orgId() {
      return this.$route.params.orgId;
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      const defaultQuery = {
        status: null,
        publish_status: null,
      };
      this.query = { ...defaultQuery, ...to.query };
      this.fetchPosts();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    if (!this.checkPermission([consts.POST_LIST_VIEW])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: "OrganizationList" });
        });
    } else {
      this.fetchMerchants();
      this.initialized = true;
    }
  },
  methods: {
    async fetchPosts() {
      try {
        this.isLoading = true;
        const params = Object.fromEntries(
          Object.entries(this.query).filter(([, value]) => value !== null)
        );

        const { data } = await postApi.getPosts(this.query.merchant_id, params);
        this.total = data.meta.total;
        this.posts = data.data.map((post) => {
          post.status = statusMap[post.status] || "";
          return post;
        });
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },

    async handleDelete(postId, title) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>文章標題：${title}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await postApi.deletePost(postId);
              await this.fetchPosts();
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },
    handleCreate() {
      this.$router.push({
        name: "PostCreate",
        query: { merchantId: this.query.merchant_id },
      });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      if (!this.query.merchant_id) {
        let defaultMerchant;
        if (data.data.length === 1) {
          defaultMerchant = data.data[0];
        } else {
          defaultMerchant = data.data
            .filter((merchant) => merchant.type === consts.MERCHANT_TYPES.LINE_TYPE)
            .shift();
        }

        if (defaultMerchant) {
          this.query.merchant_id = defaultMerchant.id;
        }
      }

      if (this.query.merchant_id) {
        var hasMerchant = this.merchants.find((item) => {
          return item.value === this.merchantId;
        });

        if (!hasMerchant) {
          this.$swal.fire({
            type: "warning",
            title: "查無此通路",
            confirmButtonText: "確定",
            confirmButtonColor: "#d33",
            reverseButtons: true,
          });
          return;
        }

        await this.fetchPosts();
      }
    },
    hiddenString(value, length) {
      value = value || "";
      const splittedValue = [...value];
      if (splittedValue.length <= length) {
        return value;
      }
      return splittedValue.slice(0, length).join("") + "...";
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    formatDate(date) {
      if (date) {
        return format(new Date(date), "yyyy-MM-dd HH:mm");
      } else {
        return "";
      }
    },
  },
};
</script>
